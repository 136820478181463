import { Box } from "@chakra-ui/react";

export function BetaPage() {
  return (
    <Box
      as="iframe"
      src="https://cdn.forms-content.sg-form.com/6a45427c-4cb9-11ed-96bf-9ea6838a7979"
      width="100%"
      height="600"
    />
  );
}
