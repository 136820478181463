import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      100: "#1269C7",
      110: "#4187d2", // light blue for button hover
      200: "#AEB4B6",
    },
  },
});
