import { Route, Routes } from "react-router-dom";
import { BetaPage, DashboardPage, HomePage } from "pages";
import { ComponentType } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Spinner } from "@chakra-ui/react";

export const ProtectedRoute = ({ component }: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Spinner />,
    returnTo: "/",
  });
  return <Component />;
};

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/beta" element={<BetaPage />} />
      <Route
        path="/dashboard"
        element={<ProtectedRoute component={DashboardPage} />}
      />
      <Route
        path="/postlogin"
        element={<ProtectedRoute component={DashboardPage} />}
      />
    </Routes>
  );
};
