import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Router } from "./router";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0ProviderWithHistory } from "./providers/Auth0";
import { BrowserRouter } from "react-router-dom";
import * as React from "react";
import { theme } from "./theme";

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <>
      <ColorModeScript />
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
              <Router />
            </ChakraProvider>
          </QueryClientProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </>
  );
};
